function schemeInit() {
  const schemePointers = document.querySelectorAll('[data-variant]');
  const schemeVariants = document.querySelectorAll('.scheme__variant');
  const schemeMainSvg = document.querySelector('.scheme__main-svg');

  schemePointers.forEach(pointer => {
    pointer.addEventListener('mouseover', () => {
      schemeMainSvg.style.display = 'none';
      schemeVariants[pointer.dataset.variant - 1].style.display = 'block';
      console.log(pointer.dataset.variant)
    })
    pointer.addEventListener('mouseout', () => {
      setTimeout(() => {
        schemeMainSvg.style.display = 'block';
        schemeVariants[pointer.dataset.variant - 1].style.display = 'none';
      }, 1000);
    })
  })
}

export { schemeInit }