function headerInit() {
  const wrapper = document.querySelector('.wrapper');
  const header = document.querySelector('.header');
  const headerBurger = document.querySelector('.header__burger');
  const headerMenuLinks = document.querySelectorAll('.header__menu-link');
  const headerHeight = window.getComputedStyle(header).height

  function headerFixation() {
    if (document.documentElement.clientWidth > 1279) {
      if (window.scrollY > (document.documentElement.clientHeight / 2)) {
        setTimeout(() => {
          header.classList.add('header--fixed');
        }, 500);
      } else {
        setTimeout(() => {
          header.classList.remove('header--fixed');
        }, 500);
      }

      if (window.scrollY > (header.clientHeight + document.documentElement.clientHeight / 4)) {
        header.classList.add('header--transition');
        wrapper.style.paddingTop = headerHeight
      } else {
        header.classList.remove('header--transition');
        wrapper.style.paddingTop = 0
      }
    }
  }

  window.addEventListener('scroll', headerFixation)
  window.addEventListener('resize', headerFixation)

  headerBurger.addEventListener('click', () => {
    header.classList.toggle('header--active');
  })

  document.addEventListener('click', (e) => {
    if (!e.target.closest('.header')) {
      header.classList.remove('header--active');
    }
  })

  headerMenuLinks.forEach(link => {
    link.addEventListener('click', () => {
      header.classList.remove('header--active');
    })
  })
}

export { headerInit }