function feedbackInit() {
  const feedbackForm = document.querySelector('.feedback__form');

  if (feedbackForm) {
    feedbackForm.addEventListener('submit', (event) => {
      event.preventDefault();
      feedbackForm.reset();
      modals.open('success')
    })
  }
}

export { feedbackInit }